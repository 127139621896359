import React, { useEffect, useState } from "react"
import * as Styled from "./styles"
import { CircularProgress, Typography } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { useTheme, useUserState } from "shared/context"
import { Button } from "../../shared/ui"
import { InProgressContentMapper, StatusWebinarMessage } from "./StatusContentMappers"
import { FormatOfWebinar } from "../../entities/Webinar"
import { axiosInstance as axios } from "../../shared/api/axiosInstance"
import { useAppSelector } from "../../shared/hooks/useAppSelector"

export const MainScreen = ({
  e,
  permission,
  course_id,
  helperText,
  linkText,
  handleEnrollButton,
  isLoading,
  name,
  badge,
  type,
}) => {
  const theme = useTheme()

  const { isAuthenticated } = useUserState()
  const [participantData, setParticipantData] = useState({ has_certificate: null, is_completed: null })

  useEffect(() => {
    if (!!permission) {
      axios
        .get(
          `content/actions/participants/my_courses/?course__type=webinar&include_requested=true&course__id=${course_id}`
        )
        .then(res => {
          let part = res.data[0]
          setParticipantData({ is_completed: part.is_completed, has_certificate: part.has_certificate })
        })
    }
  }, [])

  function handleClickGetCertificate() {
    window.open(`/event/${name}/certificate`)
  }

  return (
    <Styled.MainScreenContainer sx={{ backgroundColor: e?.backgroundColor || "#97999B" }}>
      <Styled.MainScreenTextContainer>
        {!!badge && type === "webinar" && (
          <div style={{ width: "auto", display: "flex", flexDirection: "row", gap: 16 }}>
            <FormatOfWebinar type={badge} />
          </div>
        )}
        {!!e?.Subtitle && (
          <Typography variant={"subtitle1"} sx={{ color: e.Subtitle?.color }}>
            <div dangerouslySetInnerHTML={{ __html: e.Subtitle.text }}></div>
          </Typography>
        )}
        {!!e?.H1 && (
          <Typography variant={"h1"} sx={{ color: e.H1?.color }}>
            <div dangerouslySetInnerHTML={{ __html: e.H1.text }}></div>
          </Typography>
        )}
      </Styled.MainScreenTextContainer>
      <Styled.ImageAndButtonContainer>
        {!!e?.Image && (
          <Styled.MainScreenImage>
            <LazyLoadImage
              width={"100%"}
              src={e.Image?.url}
              placeholderSrc={e.Image?.url.slice(0, -4) + "_low" + e.Image?.url.slice(-4)}
              effect="blur"
            />
          </Styled.MainScreenImage>
        )}
        <Styled.EventActionContainer>
          {isLoading ? (
            <CircularProgress size={26} />
          ) : permission ? (
            <InProgressContentMapper participantData={participantData} slug={name} type={type} />
          ) : helperText === "" ? (
            <>
              {!isAuthenticated && (
                <Typography style={{ color: "white", marginTop: 8 }}>Требуется регистрация на SYNCWOIA</Typography>
              )}
              <Button
                sx={{
                  height: theme.spacing(14),
                  paddingLeft: theme.spacing(12),
                  paddingRight: theme.spacing(12),
                  borderRadius: theme.spacing(3),
                  fontSize: theme.spacing(5),
                  [theme.breakpoints.down("xs")]: { width: "100%", padding: "auto" },
                }}
                onClick={handleEnrollButton}
                color={"accent"}
                variant={"contained"}
                height={"custom"}
              >
                ПРИСОЕДИНИТЬСЯ
              </Button>
            </>
          ) : (
            <>
              {type === "webinar" && <StatusWebinarMessage text={helperText} linkText={linkText || ""} />}
              {type === "event" && (
                <Styled.MainScreenMessage>
                  <Typography color={"white"} variant={"caption"} fontWeight={"medium"}>
                    {helperText}
                  </Typography>
                </Styled.MainScreenMessage>
              )}
            </>
          )}
        </Styled.EventActionContainer>
      </Styled.ImageAndButtonContainer>
    </Styled.MainScreenContainer>
  )
}
