import React, { useState } from "react"
import * as Styled from "./ui/Teams.style.js"
import { Button, Modal, ModalSmallTemplate, TextField } from "shared/ui"
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useTheme } from "@mui/material"
import { teamActions } from "shared/store"
import { useSelector, useDispatch } from "react-redux"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import Cookies from "js-cookie"
import useErrorMsg from "shared/hooks/useErrorMsg.ts"

export const ManageTeamModal = ({ isOpen, onClose, team }) => {
  const [title, setTitle] = useState(team?.title || "")
  const [description, setDescription] = useState(team?.description || "")
  const [type, setType] = useState(team?.access_type || "private")
  const [status, setStatus] = useState(null)
  const { errorMsg, setErrorMsg } = useErrorMsg(null)
  const course = useSelector(state => state.course.course)
  const dispatch = useDispatch()
  const theme = useTheme()

  async function handleTeam() {
    const params = {
      course: course.id,
      title: title,
      description: description,
      access_type: type,
    }
    axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
    if (team) {
      try {
        await axios.put(`/content/teams/${team.id}/`, params)
        dispatch(
          teamActions.updateTeams({
            id: team.id,
            team: { title: title, description: description, access_type: type },
          })
        )
        handleClose()
      } catch (err) {
        handleError(err.response.status)
      }
    } else {
      try {
        const response = await axios.post("/content/actions/teams/", params)
        setStatus(response.status)
        dispatch(
          teamActions.addNewTeam({
            team: { title: title, description: description, access_type: type, is_moderated: false },
          })
        )
      } catch (err) {
        handleError(err.response.status)
      }
    }
  }

  const handleError = errStatus => {
    setStatus(false)
    if (errStatus === 422) {
      setErrorMsg("На данном курсе команда с таким названием уже существует. Придумайте другое название.")
    } else if (errStatus === 423) {
      setErrorMsg("Создание команды невозможно в это время.")
    } else if (errStatus === 412) {
      setErrorMsg("Создание команды невозможно для участника курса с данной категорией.")
    } else if (errStatus === 400) {
      setErrorMsg("Использованы недопустимые символы.")
    } else if (errStatus === 403) {
      setErrorMsg("Дождитесь модерации вашей команды.")
    } else setErrorMsg("Пожалуйста, заполните пустые поля.")
  }

  const getTitle = team ? "Изменение информации о команде" : "Заявка на создание команды"
  const getLabel = team ? "Изменить" : "Отправить заявку"
  const getDescription = team ? "изменения." : "вашу заявку."

  const handleClose = () => {
    setStatus(null)
    onClose()
  }

  return (
    <>
      {!status && (
        <Modal title={getTitle} isOpen={isOpen} onClose={onClose}>
          <Styled.ContentWrapper sx={{ marginTop: theme.spacing(13) }}>
            <TextField
              error={!!errorMsg}
              id={"team-title-RegistrationField"}
              variant="outlined"
              label={"Название команды"}
              multiLine
              onChange={e => setTitle(e.target.value)}
              value={title}
              helperText={errorMsg}
              size="large"
            />
            <TextField
              id={"team-description-RegistrationField"}
              variant="outlined"
              label={"Описание"}
              multiLine
              onChange={e => setDescription(e.target.value)}
              value={description}
              size="large"
              style={{ marginTop: 16 }}
            />
            <FormControl style={{ marginTop: 40 }}>
              <FormLabel id="demo-radio-buttons-group-label">
                <Typography variant={"h4"}>Выберите тип команды</Typography>
              </FormLabel>
              <Styled.FormWrapper>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={type}
                  name="radio-buttons-group"
                  onChange={e => setType(e.target.value)}
                  style={{ marginTop: 25.5 }}
                >
                  <FormControlLabel
                    value="public"
                    id={"public-radio"}
                    control={<Radio />}
                    label={
                      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
                        Публичная
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="private"
                    id={"private-radio"}
                    control={<Radio />}
                    label={
                      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
                        Непубличная
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="open"
                    id={"open-radio"}
                    control={<Radio />}
                    label={
                      <Typography variant={"body1"} fontWeight={"medium"} fontSize={"medium"}>
                        Открытая
                      </Typography>
                    }
                  />
                </RadioGroup>
                <Styled.FormDescription>
                  <Typography variant={"body1"} fontWeight={"small"}>
                    Любой может оставить запрос на вступление в команду
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"small"}>
                    Только по приглашениям
                  </Typography>
                  <Typography variant={"body1"} fontWeight={"small"}>
                    Вступить в команду может любой участник курса (если соответствует ограничениям)
                  </Typography>
                </Styled.FormDescription>
              </Styled.FormWrapper>
            </FormControl>
            <Styled.SubmitButton>
              <Button variant={"contained"} onClick={handleTeam}>
                {getLabel}
              </Button>
            </Styled.SubmitButton>
          </Styled.ContentWrapper>
        </Modal>
      )}
      {status && (
        <ModalSmallTemplate
          title={getTitle}
          text={`Ожидайте, пока модератор рассмотрит ${getDescription}`}
          isOpen={isOpen}
          onClose={handleClose}
        />
      )}
    </>
  )
}
