export const defaultTemplate = {
  props: [
    {
      type: "main_screen",
      index: 1,
      backgroundColor: "#29A4C1",
      H1: { text: "Тут название организации", color: "#FFF" },
      BaseText: {
        text: "Тут должно быть какое-то подробное описсание организации",
        color: "#FFF",
      },
      Image: { url: "https://syncwoia.com/static/media/extra/1/newImageMainPark/newImageMainPark/image_main.svg" },
    },
    {
      type: "tab",
      index: 2,
      backgroundColor: "#29A4C1",
      textColor: "#FFF",
      lineColor: "#FBE521",
      activeTextColor: "#FBE521",
      arrowColor: "#FFF",
    },
    {
      title: "Описание",
      index: 3,
      type: "description_company",
      backgroundColor: "#FFF",
      H2: { text: "Тут заголовок блока", color: "#000", lineColor: "#FBE521" },
      Text: {
        text: "Тут должен быть большой текст",
      },
      Image: { url: "https://syncwoia.com/static/media/extra/1/smartpark/smartpark/image_about.jpg" },
    },
    {
      type: "video",
      index: 4,
      backgroundColor: "#FFF",
      url: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video/video.mp4",
      title: "ВИДЕО",
    },
    {
      title: "Преимущества",
      type: "advantages",
      index: 5,
      backgroundColor: "#FFF",
      H2: { lineColor: "#FBE521", color: "rgba(0, 0, 0, 1)", text: "Тут должно быть название блока" },
      options: [
        {
          H3: { text: "ПУНКТ 1", color: "#000" },
          BaseText: {
            text: "Описание пункта 1",
            color: "#000",
          },
          Image: { url: "https://syncwoia.com/static/media/extra/1/stages/stages/stage_1.png" },
        },
        {
          Image: { url: "https://syncwoia.com/static/media/extra/1/stages/stages/stage_2.png" },
          H3: { text: "ПУНКТ 2", color: "#000" },
          BaseText: {
            text: "Описание пункта 2",
            color: "#000",
          },
        },
        {
          Image: { url: "https://syncwoia.com/static/media/extra/1/stages/stages/stage_3.png" },
          H3: { text: "ПУНКТ 3", color: "#000" },
          BaseText: {
            text: "Описание пункта 3",
            color: "#000",
          },
        },
      ],
    },
    {
      title: "ПРОГРАММЫ",
      index: 6,
      type: "projects",
      backgroundColor: "#FFF",
      H2: { text: "Программы", color: "#000", lineColor: "#FFF" },
    },
    {
      type: "gallery",
      title: "ГАЛЛЕРЕЯ",
      index: 7,
      backgroundColor: "#2DB8C7",
      arrowColor: "#FBE521",
      options: [
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_1.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_2.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_3.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_4.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_5.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_6.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_7.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_8.jpg" },
        { url: "https://syncwoia.com/static/media/extra/1/photoGallery/photoGallery/img_9.jpg" },
      ],
    },
    {
      title: "ВИДЕО-ГАЛЕРЕЯ",
      type: "video_gallery",
      index: 8,
      backgroundColor: "#FFF",
      arrowColor: "#FBE521",
      H2: { text: "Видео-галлерея", color: "#000", lineColor: "#212121" },
      options: [
        {
          preview: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video1/preview1.png",
          video: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video1/video_1.mp4",
        },
        {
          preview: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video2/preview2.png",
          video: "https://www.wiki.syncwoia.com/smartpark.kz/smartpark_video2/video_2.mp4",
        },
      ],
    },
    {
      title: "ОТЗЫВЫ",
      type: "reviews",
      index: 9,
      backgroundImage: "https://syncwoia.com/static/media/extra/1/smartpark/smartpark/feedback_img.jpg",
      H2: { text: "Отзывы", color: "#FFF", lineColor: "#FFF" },
      options: [
        {
          user_data: {
            status: "student",
            organization: "null",
            first_name: "Имя 1",
            last_name: "Фамилия 1",
            middle_name: "Отчество 1",
            about: "",
          },
          text: "Тут текст отзыва 1.",
        },
        {
          user_data: {
            status: "student",
            organization: "null",
            first_name: "Имя 2",
            last_name: "Фамилия 2",
            middle_name: "Отчество 2",
            about: "",
          },
          text: "Тут текст отзыва 2.",
        },
      ],
    },
    {
      type: "partners",
      title: "Партнеры",
      index: 10,
      backgroundColor: "#FFF",
      arrowColor: "#FBE521",
      H2: { text: "Организаторы и партнеры", color: "#000", lineColor: "#FBE521" },
      options: [
        {
          website: "https://syncwoia.com/",
          url: "https://syncwoia.com/static/media/extra/837/orglogo/syncwoia-logo.png",
        },
      ],
    },
    {
      title: "ВОПРОСЫ",
      type: "faq",
      backgroundColor: "#29A4C1",
      index: 11,
      textColor: "#FFF",
      iconColor: "#FFF",
      H2: { text: "Вопросы и ответы", color: "#FFF", lineColor: "#FBE521" },
      options: [
        {
          question: "Вопрос 1",
          answer: "Ответ 1",
        },
        {
          question: "Вопрос 2",
          answer: "Ответ 2",
        },
        { question: "Вопрос 3", answer: "Ответ 3" },
      ],
      lineColor: "#FBE521",
      borderBottomColor: "#FFF",
      textFieldColor: "#FFF",
    },
    {
      type: "feedback_form",
      backgroundColor: "#29A4C1",
      borderBottomColor: "#FBE521",
      index: 12,
      H2: { text: "Узнать больше", color: "#FFF", lineColor: "#FBE521" },
      baseText: { text: "Остались вопросы? Напишите нам!", color: "#FFF" },
      textFieldColor: "#FFF",
      button: { color: "#FFF", backgroundColor: "#F21" },
    },
  ],
}
