import { styled } from "@mui/material/styles"
import { Box } from "@mui/material"

export const CatalogMainDiv = styled("div")(({ theme }) => ({
  marginTop: "75px",
  width: "100%",
}))

export const SearchBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(3),
  backgroundColor: "#DEE7F2",
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(10),
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(4),
  },
}))

export const TagsMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "wrap",
  gap: theme.spacing(4),
  overflowX: "auto",
  width: "100%",
}))

export const CourseTypesMainDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(6),
}))

export const CoursesNotFoundMainDiv = styled("div")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(31),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
}))

export const IsLoadingMainDiv = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(10),
  marginBottom: theme.spacing(10),
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
}))

export const NewItemsMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  justifyContent: "space-between",
  width: "100%",
  paddingTop: theme.spacing(16),
  paddingBottom: theme.spacing(16),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: theme.spacing(6),
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))

export const NewItemsDescriptionMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: theme.spacing(12),
  background: "linear-gradient(90deg, #FF575A 0%, #FF6857 100%)",
  width: "30%",
  borderRadius: theme.spacing(3),
  height: "340px",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
}))

export const NewItemsSliderDiv = styled("div")(({ theme }) => ({
  "& .slick-list": {
    "& .slick-slide > div": {
      padding: "0 8px",
    },
  },
  "& .slick-arrow": {
    color: "#000",
    fontSize: "24px",
    "&:hover": {
      color: "#000",
      opacity: "0.5",
    },
  },
  height: "340px",
  width: "66%",
  [theme.breakpoints.down("lg")]: {
    width: "100%",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    padding: `${0} ${0}`,
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
  },
}))

export const CourseItemMainDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  width: "100%",
  justifyContent: "flex-start",
  cursor: "pointer",
  backgroundColor: "#FFF",
  boxShadow: "0px 0px 10px 0px #3636361A",
  borderRadius: theme.spacing(3),
  height: "340px",
}))

export const CourseItemImageDiv = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100%",
  height: "auto",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: theme.spacing(3),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}))

export const CourseItemContent = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  width: "100%",
  padding: `0 ${theme.spacing(3)}`,
  height: "100%",
}))

export const CourseItemImage = styled("img")(({ theme }) => ({
  height: "140px",
  objectFit: "cover",
  borderTopRightRadius: theme.spacing(3),
  borderTopLeftRadius: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    height: "140px",
  },
}))

export const CourseDescriptionData = styled("div")(({ theme }) => ({
  borderTop: "1px solid #E0E0E0",
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  marginTop: "auto",
  padding: `${theme.spacing(3)} 0`,
}))

export const CourseDataItem = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "flex-start",
  gap: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}))
