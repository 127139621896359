import { useEffect, useState } from "react"
import MembersFilters from "./MembersFilters"
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { TableTotalCount as ParticipantsCount, TableLoader } from "shared/assets"
import * as Styled from "./Organizations.styles"
import { MemberChip } from "entities/Organization/ui/MemberChip"
import { SetMemberStatus } from "features/Organization/SetMemberStatus"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { OrganizationMember } from "entities/Organization/model/Organization.types"
// @ts-ignore
import Cookies from "js-cookie"
import VisibilitySensor from "react-visibility-sensor"

const tableFields = ["ФИО", "Почта", "Статус/Сменить статус", "Удалить участника"]

export const MembersTable = ({
  members,
  setMembers,
}: {
  members: Array<OrganizationMember>
  setMembers: React.Dispatch<React.SetStateAction<OrganizationMember[]>>
}) => {
  const organization = useAppSelector(state => state.organization.currentOrganization)
  const [nextPage, setNextPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [counts, setCounts] = useState(0)
  const [filterFields, setFilterFields] = useState({ last_name: "", email: "", role: "" })
  // @ts-ignore

  useEffect(() => {
    if (!organization?.id) return
    setIsLoading(true)
    const controller = new AbortController()
    axios
      .get(`/organizations/${organization?.id}/members/`, {
        signal: controller.signal,
        params: { page: 1, ...filterFields },
      })
      .then(res => {
        setMembers([...res.data.results])
        setNextPage(res.data.next)
        setHasNextPage(!!res.data.next)
        setIsLoading(false)
        setCounts(res.data.count)
      })
  }, [organization, filterFields])

  function loadMore() {
    if (!!nextPage && !!hasNextPage) {
      setIsLoading(true)
      axios
        .get(`/organizations/${organization?.id}/members/`, { params: { page: nextPage, ...filterFields } })
        .then(res => {
          setMembers(prev => [...prev, ...res.data.results])
          setNextPage(res.data.next)
          setHasNextPage(!!res.data.next)
          setIsLoading(false)
          setCounts(res.data.count)
        })
    }
  }

  function deleteUser(user: OrganizationMember) {
    if (!!organization) {
      axios.defaults.headers["X-CSRFTOKEN"] = Cookies.get("csrftoken")
      axios.delete(`/organizations/${organization.id}/members/`, { params: { email: user.email } }).then(res => {
        setMembers(prevState => prevState.filter(e => e.email !== user.email))
      })
    }
  }

  function updateUiRole(member: OrganizationMember, role: string) {
    let index = members.indexOf(member)
    members[index].member_role = role
    setMembers([...members])
  }

  function handleChangeFilter(field: "role" | "last_name" | "email", value: string | null) {
    filterFields[field] = value || ""
    setFilterFields({ ...filterFields })
  }

  return (
    <>
      <MembersFilters fields={filterFields} setFields={handleChangeFilter} />
      <ParticipantsCount totalCount={counts} filterCount={members.length} />
      <Styled.CustomTableContainer sx={{ marginTop: "24px", marginBottom: "40px" }}>
        <Table stickyHeader={true}>
          {/*@ts-ignore*/}
          <TableHead sx={{ width: "100%", overflowX: "auto" }}>
            <TableRow>
              {tableFields.map(e => (
                <TableCell align={"center"} sx={{ backgroundColor: "#F9F9F9" }}>
                  <Typography variant={"caption"} fontWeight={"small"} sx={{ fontSize: 14 }}>
                    {e}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <VisibilitySensor onChange={loadMore} partialVisibility={"bottom"}>
            <TableBody>
              {members.map(e => (
                <TableRow>
                  <TableCell align={"center"}>
                    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
                      {`${e.first_name} ${e.last_name} ${e.middle_name || ""}`}
                    </Typography>
                  </TableCell>
                  <TableCell align={"center"}>
                    <Typography variant={"caption"} fontWeight={"medium"} sx={{ fontSize: "14px" }}>
                      {e.email}
                    </Typography>
                  </TableCell>
                  <TableCell align={"center"}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <MemberChip label={e.member_role} />
                      {e.member_role === "follower" && <SetMemberStatus updateUser={updateUiRole} user={e} />}
                    </div>
                  </TableCell>
                  <TableCell align={"center"}>
                    {e.member_role === "follower" && <Styled.CustomDeleteIcon onClick={() => deleteUser(e)} />}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </VisibilitySensor>
        </Table>
        <TableLoader isLoading={isLoading}>Таблица прогружается. Пожалуйста, подождите</TableLoader>
      </Styled.CustomTableContainer>
    </>
  )
}
