import { useState } from "react"
import { Button, Dropdown, IconButton } from "shared/ui"
import { NotificationIcon } from "entities/Organization/ui/styles/PaticipantsTable.styles"
import { setParticipantStatus } from "entities/Participant/api/participant"
import { useAppSelector } from "shared/hooks/useAppSelector"
import { getCourse } from "entities/Course/api/course"
import styled from "@emotion/styled"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import EditPaticipantModal from "entities/Organization/ui/EditPaticipantModal"
import SendSingleNotification from "features/Notifications/SendSingleNotification"
import { RejectParticipantModal } from "features"
import { IParticipant } from "entities/Participant/model/participant.types"

interface IProps {
  participant: IParticipant
  isAccessButtons?: boolean
  categories: Record<string, string>
  setParticipants: React.Dispatch<React.SetStateAction<IParticipant[]>>
  setTotal: React.Dispatch<React.SetStateAction<number>>
  triggerReloading: () => void
}

export const ParticipantAccess = ({
  participant,
  isAccessButtons = true,
  categories,
  setParticipants,
  setTotal,
  triggerReloading,
}: IProps) => {
  const course = useAppSelector(state => state.course.course)
  const [editParticipantDialog, setEditParticipantDialog] = useState(null)
  const [infoFields, setInfoFields] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [category, setCategory] = useState("Без категории")
  const [isOpenReject, setIsOpenReject] = useState(false)

  async function handleAccess(status: string, feedback?: string) {
    try {
      const payload = {
        user: participant.user,
        course: course?.id,
        status: status,
        category: category,
        feedback,
      }
      await setParticipantStatus(payload)
      setParticipants(prev => prev.filter((item: any) => item.id !== participant.id))
      setTotal((prev: number) => (prev -= 1))
      triggerReloading()
    } catch {}
  }

  async function getCourseInfo() {
    if (!course?.id) return
    const response = await getCourse(course?.id)
    if (response.info_fields) {
      setInfoFields([...response.info_fields])
    } else {
      setInfoFields([])
    }
  }

  const handleEdit = async () => {
    await getCourseInfo()
    setEditParticipantDialog(participant)
  }

  function handleDialog(participant) {
    if (!openDialog) {
      const params = {
        title: course?.title,
        user: participant.user,
      }
      setOpenDialog({ ...params })
    } else {
      setOpenDialog(false)
    }
  }

  const onAccess = () => {
    if (Object.keys(categories).length === 1) {
      return handleAccess("in_progress", category)
    }
    if (!isSubmit) return setIsSubmit(true)
    handleAccess("in_progress", category)
  }

  const onReject = () => {
    if (isSubmit) return setIsSubmit(false)
  }

  return (
    <>
      <EditPaticipantModal
        editParticipantDialog={editParticipantDialog}
        setEditParticipantDialog={setEditParticipantDialog}
        infoFields={infoFields}
      />
      <RejectParticipantModal isOpen={isOpenReject} onClose={() => setIsOpenReject(false)} onReject={handleAccess} />
      <SendSingleNotification
        user={openDialog?.user}
        title={openDialog?.title}
        isOpen={openDialog}
        onClose={handleDialog}
      />
      <ActionsWrapper>
        {isAccessButtons && (
          <ButtonsWrapper>
            <Button
              variant={"contained"}
              size={"small"}
              onClick={onAccess}
              color={isSubmit ? "accent" : "primary"}
              width={131}
            >
              {isSubmit ? "Подтвердить" : "Дать доступ"}
            </Button>
            <Button
              variant={"outlined"}
              size={"small"}
              onClick={() => (isSubmit ? onReject() : setIsOpenReject(true))}
              width={131}
            >
              {isSubmit ? "Отменить" : "Отклонить"}
            </Button>
          </ButtonsWrapper>
        )}
        {isSubmit ? (
          <CategoryWrapper>
            <Dropdown
              id={"invite--status"}
              name={"category"}
              value={category || undefined}
              onChange={e => setCategory(e.target.value)}
              options={categories}
              label={"Категория"}
              width={112}
              size={"small"}
            />
          </CategoryWrapper>
        ) : (
          <>
            <IconButton
              id={`notification-${participant.id}`}
              color={"inherit"}
              size={"medium"}
              onClick={() => handleDialog(participant)}
            >
              <NotificationIcon />
            </IconButton>
            <IconButton color={"inherit"} size={"medium"} onClick={handleEdit}>
              <BorderColorIcon />
            </IconButton>
          </>
        )}
      </ActionsWrapper>
    </>
  )
}

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`

const ButtonsWrapper = styled.div`
  flex-shrink: 1;

  & > :not(:last-child) {
    margin-bottom: 8px;
  }
`

const CategoryWrapper = styled.div`
  flex-grow: 1;
`
